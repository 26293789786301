<template>
  <!-- Header mobile -->
  <q-layout view="hHh lpR fFf" style="min-height: 0">
    <q-header class="text-white fixed-top header-desktop q-mb-xl q-pt-sm">
      <q-toolbar class="justify-between" style="height: 100%">
        <router-link to="/">
          <img
            class=""
            :src="settings.logo ? settings.logo : defaultImageApp"
            style="max-width: 100%; max-height: 45px"
          />
        </router-link>
        <div class="flex items-center">
          <CircleInfo />
          <div class="points">
            <q-toolbar-title class="title-points">
              {{ settings.currency }}: {{ points.points.points }}
            </q-toolbar-title>
          </div>
        </div>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>

  <!-- Header desktop -->
  <q-layout view="hHh lpR fFf" style="min-height: 0">
    <q-header
      class="container-margin text-white fixed-top header-mobile q-pt-sm"
      style="background-color: white"
    >
      <q-toolbar class="justify-between q-pt-sm">
        <router-link to="/">
          <img
            class=""
            :src="settings.logo ? settings.logo : defaultImageApp"
            style="max-width: 100px; max-height: 55px"
          />
          <q-tooltip anchor="bottom middle" self="top middle">
            {{ $t("General.home") }}
          </q-tooltip>
        </router-link>
        <div class="row items-center q-pr-md">
          <CircleInfo />
          <div class="points">
            <q-toolbar-title class="title-points">
              {{ settings.currency }}: {{ points.points.points }}
              <q-tooltip anchor="bottom middle" self="top middle">
                 {{ $t("General.points") }}
              </q-tooltip>
            </q-toolbar-title>
          </div>
          <q-item>
            <LanguageSwitcher />
          </q-item>
          <q-item>
            <LogOut />
          </q-item>

        </div>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { defineAsyncComponent } from "vue";

import settings from "@/mixins/settings";
import points from "@/mixins/points";
import language from "@/mixins/language";

const LanguageSwitcher = defineAsyncComponent(() =>
  import("../../shared/components/LanguageSwitcher.vue")
);

const CircleInfo = defineAsyncComponent(() =>
  import("../../module-2-main-pages/components/profile/CircleInfo.vue")
);

const LogOut = defineAsyncComponent(() =>
  import("../../module-2-main-pages/components/profile/LogOut.vue")
);

export default {
  mixins: [settings, points, language],
  components: {
    CircleInfo,
    LanguageSwitcher,
    LogOut,
  },
  mounted() {
    this.getPoints();
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-header {
  align-items: center;
  background-color: $gray;
  display: flex;
  height: 60px;
}

.points {
  /*border-radius: 25px 25px 25px 0px;
  border: 1px solid v-bind("settings.color_primary");*/
  padding: 0.5rem;
  .title-points {
    color: #818181;
    font-size: 12px;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 767px) {
  .header-mobile {
    display: block;
  }

  .header-desktop {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-desktop {
    display: block;
  }

  .header-mobile {
    display: none;
  }
}

select {
  border-radius: 25px 25px 25px 0px;
  min-width: 100px;
  padding: 5px;
  border: 1px solid v-bind("settings.color_primary");
  height: 36px;
}
</style>
